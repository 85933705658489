import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "withdrawHistory",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const activeTab = ref("Paying");

    const onClickLeft = () => {
      history.back();
    }; // 控制弹窗的可见性


    const detailsDialogVisible = ref(false);
    const currentDetail = ref({
      amount: "",
      orderno: "",
      time: "",
      afterAmount: "",
      remark: ""
    }); // Success Tab 的数据

    const successItems = ref([{
      amount: 1000,
      orderno: "202409221655550263334",
      time: "2024-09-22 16:55:55",
      afterAmount: "25921.00",
      remark: "receive deducted"
    }, {
      amount: 6300,
      orderno: "202409221652297434618",
      time: "2024-09-22 16:52:29",
      afterAmount: "25921.00",
      remark: "receive deducted"
    }, {
      amount: 3100,
      orderno: "2024092216220045731624",
      time: "2024-09-22 16:22:00",
      afterAmount: "25921.00",
      remark: "receive deducted"
    }, {
      amount: 1740,
      orderno: "2024092216011716985425",
      time: "2024-09-22 16:01:17",
      afterAmount: "25921.00",
      remark: "receive deducted"
    }, {
      amount: 5900,
      orderno: "2024092122013684531386",
      time: "2024-09-21 22:01:36",
      afterAmount: "25921.00",
      remark: "receive deducted"
    }]); // 加载状态

    const loading = ref(false);
    const finished = ref(true); // 显示详情弹窗

    const showDetails = item => {
      currentDetail.value = { ...item
      };
      detailsDialogVisible.value = true;
    }; // 关闭弹窗


    const closeDialog = () => {
      detailsDialogVisible.value = false;
    }; // 加载更多数据


    const onLoad = () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        finished.value = true;
      }, 1500);
    };

    return {
      onClickLeft,
      activeTab,
      successItems,
      loading,
      finished,
      showDetails,
      closeDialog,
      detailsDialogVisible,
      currentDetail,
      onLoad
    };
  }

};